<template>
  <div>
    <vue3-snackbar
      top
      center
      success="#BD976E"
      :duration="2000"
    ></vue3-snackbar>
    <div
      class="flex flex-col gap-5 md:flex-row items-center justify-between mb-5"
    >
      <h3 class="text-xl font-semibold text-theme-secondary">
        Your Orders
      </h3>
      <div class="flex items-center space-x-5">
        <button
          class="flex-shrink-0 h-9 inline-flex text-xs items-center space-x-1 bg-transparent text-theme-secondary border border-gray-200 bg-gray-200 hover:bg-theme-secondary hover:text-white px-4 py-1 rounded leading-none transition-colors duration-300"
          @click="showDownloadModal = true"
        >
          <DownloadIcon class="w-3.5 h-3.5 -mt-0.5" />
          <span class="flex-shrink-0">Multiple Invoices</span>
        </button>
        <div
          class="relative bg-transparent border border-gray-200 rounded w-full max-w-xs text-gray-400 focus-within:text-gray-600"
        >
          <div
            class="pointer-events-none absolute inset-y-0 left-1 flex items-center"
          >
            <SearchIcon class="h-5 w-5" />
          </div>
          <input
            id="search-field"
            class="block h-full bg-transparent w-full border-transparent py-2.5 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder="Search"
            type="search"
            name="search"
            v-model="search"
            @keyup="getSearch"
          />
        </div>
      </div>
    </div>
    <table class="orders-table">
      <thead class="bg-white min-w-full">
        <tr class="w-full">
          <th scope="col">Order</th>
          <th scope="col" class="hidden lg:table-cell">Order Date</th>
          <th scope="col" class="hidden 2xl:table-cell">Status</th>
          <th scope="col" class="hidden lg:table-cell">Amount</th>
          <th scope="col" class="hidden 2xl:table-cell">Payment</th>
          <th scope="col" class="hidden lg:table-cell" width="200">Invoice</th>
          <th scope="col" width="120">Details</th>
        </tr>
      </thead>
      <tbody v-if="orders.loading">
        <RowLoader :rows="11" />
      </tbody>
      <tbody v-else class="bg-white">
        <tr v-if="!(orders ? orders.data.data.length > 0 : null)">
          <td colspan="7" class="text-center">
            <div class="orders-empty">
              <img src="@/assets/images/emptybox.svg" alt="Empty Cart" />
              <p>You dont't have any orders yet.</p>
              <span
                ><router-link to="/trending">Click here</router-link> for
                suggestions we think would do well at your store.</span
              >
            </div>
          </td>
        </tr>
        <template v-else>
          <OrdersRow
            v-for="order in orders.data.data"
            :key="order.id"
            :order="order"
          />
        </template>
      </tbody>
    </table>

    <Pagination
      :total="orders.data.last_page"
      :active="activePage"
      class="my-5"
      @paginate="changeActivePage"
    />

    <!-- Download Modal -->
    <ModalFull
      :is-visible="showDownloadModal"
      :reset-container="true"
      @close="showDownloadModal = false"
      container-class="w-full max-w-2xl"
    >
      <DownloadInvoice @close="closeModal" />
    </ModalFull>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import { SearchIcon } from "@heroicons/vue/solid";
import Account from "@/modules/Account";
import Pagination from "./Pagination.vue";
import { ref } from "vue";
import debounce from "lodash/debounce";
import RowLoader from "@/components/loaders/OrdersRow.vue";
import OrdersRow from "./OrdersRow.vue";
import { DownloadIcon } from "@heroicons/vue/outline";
import DownloadInvoice from "./DownloadInvoices.vue";

export default {
  name: "Orders",
  mixins: [Mymixin],
  components: {
    Pagination,
    SearchIcon,
    RowLoader,
    OrdersRow,
    DownloadIcon,
    DownloadInvoice,
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
    trackingLink(data) {
      window.open(data);
    },
    getSearch: debounce(function() {
      this.changeActivePage(1);
    }, 500),
    closeModal(l) {
      let text = "Please check your email to download the " + (l > 1 ? "invoices." : "invoice.");
      if(l){
        this.$snackbar.add({
          type: "success",
          text: text,
          dismissible: false,
        });
      }
      this.showDownloadModal = false;
    },
  },
  setup() {
    const { orders, getOrders } = Account();
    getOrders();
    const activePage = ref(1);
    const search = ref("");
    const showDownloadModal = ref(false);

    const changeActivePage = (page) => {
      page ? (activePage.value = page) : null;
      if (page) getOrders(page, search.value);
      if (window.innerWidth < 768) {
        document
          .querySelector(".orders-table-data")
          ?.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      orders,
      activePage,
      changeActivePage,
      search,
      showDownloadModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.orders-table {
  @apply min-w-full divide-y divide-gray-200;

  th {
    @apply relative px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
}

.orders-empty {
  @apply flex flex-col items-center space-y-2 px-4 py-20;
  p {
    @apply text-base text-brand-secondary font-semibold text-center;
  }
  span {
    @apply text-sm text-brand-body w-72 text-center;
    a {
      @apply border-b border-dashed border-brand-body;
    }
  }
}
</style>
